<template>
  <AppModal v-model="dialog" :title="$t('removeThisDestination')" max-width="450" :show-close-button="!loading" :persistent="loading">
    <div>
      <div v-if="!selectedBroadcast">
        <div v-if="providers.length">
          <div v-for="(provider, key) in providers" :key="provider.userSessionProviderId">
            <v-divider v-if="key !== 0" class="my-5"></v-divider>
            <div>{{ $t('areYouSureToDeletePost', { itemName: provider.providerShortCode }) }}</div>
            <div class="d-flex align-center">
              <DestinationAvatar :avatar="provider.avatarUrl" :destination-types="provider.providerShortCode" icon-position="right" />
              <span class="ml-3">{{ provider.userProviderDetail.displayName }}</span>
            </div>
            <div v-if="provider.userProviderDetail.providerShortCode === 'Youtube'" class="d-flex">
              <v-checkbox :value="false" :label="$t('broadcasts.deleteOnYoutube')" hide-details @change="changeDeleteOnYoutube(provider)"></v-checkbox>
            </div>
          </div>
        </div>
        <div v-else>{{ $t('areYouSureToDelete', { itemName: broadcastSessionName || $t('broadcast') }) }}</div>
      </div>
      <div v-else>
        <div class="d-flex align-center mb-4">
          <DestinationAvatar :avatar="selectedBroadcast.avatarUrl" :destination-types="selectedBroadcast.providerShortCode" icon-position="right" />

          <span class="ml-3">{{ selectedBroadcast.userProviderDetail.displayName }}</span>
        </div>
        <div>{{ $t('areYouSureToDeletePost', { itemName: selectedBroadcast.providerShortCode }) }}</div>
        <div v-if="selectedBroadcast.providerShortCode === 'Youtube'" class="d-flex">
          <v-checkbox :value="false" :label="$t('broadcasts.deleteOnYoutube')" hide-details @change="changeDeleteOnYoutube(selectedBroadcast)"></v-checkbox>
        </div>
      </div>
    </div>
    <div class="d-flex align-center justify-start mt-4">
      <AppButton color="var(--v-error-base)" class="mr-2" @click="deleteBroadcast" :loading="loading">
        <span>{{ $t('broadcasts.deleteBroadcast') }}</span>
      </AppButton>
      <AppButton @click="close" :loading="loading">
        <span>{{ $t('cancel') }}</span>
      </AppButton>
    </div>
  </AppModal>
</template>
<script>
import { mapActions } from 'vuex';
import { NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';
import xssService from '@/services/xss.service';
import DestinationAvatar from '@/components/DestinationAvatar';

export default {
  name: 'DeleteBroadcastModal',
  components: { DestinationAvatar },
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
    selectedBroadcast: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      deleteOnYoutube: {},
      selecteds: [],
    };
  },
  computed: {
    providers() {
      return this.broadcastRawItems?.items || [];
    },
    broadcastRawItems() {
      return this.broadcast?.raw || this.broadcast;
    },
    broadcastSessionName() {
      return this.broadcastRawItems?.sessionName;
    },
    payload() {
      const data = { sessionId: this.broadcast.id || this.broadcast.sessionId };
      const deleteList = this.selecteds.map((item) => {
        return {
          userSessionProviderId: item.userSessionProviderId,
          deleteOnProvider: item.providerShortCode === 'Youtube' ? item.deleteOnProvider : false,
        };
      });
      if (!this.selectedBroadcast) {
        data.deleteList = deleteList;
      } else {
        data.deleteProvider = deleteList[0];
      }
      return data;
    },
    method() {
      return !this.selectedBroadcast ? 'deleteSession' : 'deleteProvider';
    },
  },
  created() {
    for (let i in this.providers) {
      if (this.providers[i].userProviderDetail.providerShortCode === 'Youtube') {
        this.deleteOnYoutube[this.providers[i].userSessionProviderId] = false;
      }
    }
    if (this.selectedBroadcast) {
      this.selecteds.push({ ...this.selectedBroadcast, deleteOnProvider: false });
    } else {
      this.selecteds = [
        ...this.providers.map((provider) => {
          return {
            ...provider,
            deleteOnProvider: false,
          };
        }),
      ];
    }
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async deleteBroadcast() {
      try {
        this.loading = true;
        await xssService[this.method](this.payload);
        this.$emit('deleted');
      } catch (err) {
        console.log(err);
        this.showToastNotification({ body: 'error.broadcsatDeleteFailed', config: { type: TOAST_TYPE.ERROR } });
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.dialog = false;
      this.$modal?.close();
    },
    changeDeleteOnYoutube(selectedBroadcast) {
      this.deleteOnYoutube[selectedBroadcast.userSessionProviderId] = !this.deleteOnYoutube[selectedBroadcast.userSessionProviderId];
      const provider = this.selecteds.find((selected) => selected.userSessionProviderId === selectedBroadcast.userSessionProviderId);
      provider.deleteOnProvider = !provider.deleteOnProvider;
    },
  },
};
</script>

<style lang="scss" scoped>
.skip-for-now {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.you-can-also-add {
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
}
</style>

