<template>
  <AppModal
    max-width="500"
    show-actions
    :title="$t('meetingRequest.invite')"
    :cancel-button-text="$t('cancel')"
    :confirm-button-text="$t('confirm')"
    :auto-close="false"
    @close="close"
  >
    <div class="d-flex align-center justify-center meeting-request-modal-wrapper">
      <div class="d-flex flex-column align-center text-center input-wrapper">
        <div class="sub-title my-3">{{ $t('meetingRequest.inviteRequest') }}</div>
        <div class="content">{{ $t('meetingRequest.inviteUsersToStream', { sessionName }) }}</div>
        <v-autocomplete
          autocomplete="off"
          background-color="var(--v-primary-lighten1)"
          color="var(--v-white-base)"
          v-model="selectedParticipantEmails"
          :error="errors.email"
          hide-details
          outlined
          deletable-chips
          multiple
          small-chips
          attach
          hide-selected
          class="primary autocomplete-custom mt-3 w-full"
          :class="{ 'no-item': !autocompleteHasItem }"
          :items="emailList"
          :label="$t('meetingRequest.emailAddresses')"
          :search-input.sync="emailSearchValue"
          :hide-no-data="!emailList.length"
          @blur="onBlurAutoComplete"
          @click.native="checkEmailListNull"
          @keydown.enter.space.tab.stop="onEnterEmail"
        >
          <template slot="no-data">
            <div v-if="emailListSearching" class="autocomplete-data-1">{{ $t('searching') }}</div>
            <div v-else-if="!emailListSearching" class="autocomplete-data-2"></div>
          </template>
        </v-autocomplete>
        <v-alert v-if="errors.email" class="mt-4" type="error">
          {{ $t('meetingRequest.typeAnEmailAddressAndPressEnter') }}
        </v-alert>
      </div>
    </div>
  </AppModal>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import { STUDIO, USER } from '@/constants/modules';
import { validateEmail } from '@/helpers/validation';

export default {
  name: 'InviteParticipantModal',
  props: {
    sessionId: {
      type: String,
      requited: true,
    },
    sessionName: {
      type: String,
      requited: true,
    },
  },
  data() {
    return {
      selectedParticipantEmails: [],
      emailList: [],
      emailListSearching: false,
      emailSearchValue: '',
      autocompleteHasItem: false,
      errors: {
        email: false,
      },
    };
  },
  computed: {
    ...mapState(USER, ['user']),
    participantsEmail() {
      return this.selectedParticipantEmails.join(', ');
    },
    payload() {
      const { participantsEmail, user } = this;
      const calendarTemplate = {
        startDate: {
          Seconds: 100000000,
          Nanos: 0,
        },
        endDate: {
          Seconds: 100000000,
          Nanos: 0,
        },
        shareParticipantList: false,
        summary: this.sessionName,
      };

      const razorObj = {
        IsCurrentMeetingAttr: 'false',
        InviterFullName: `${user.firstName} ${user.lastName}`,
        SessionId: this.sessionId,
        SessionName: this.sessionName,
      };

      return {
        subject: this.sessionName,
        participantsEmail,
        addCalendar: false,
        calendarTemplate,
        shareParticipantList: false,
        templateKey: 'BroadcastInviteXss',
        razorObj: JSON.stringify(razorObj),
      };
    },
  },
  created() {
    this.emailSearchValue = '';
  },
  methods: {
    ...mapActions(STUDIO, ['sendMeetingRequest']),
    close(type) {
      if (type === 'confirm') {
        this.send();
      } else {
        this.$emit('close');
      }
    },
    onEnterEmail() {
      const searchValueArr = this.emailSearchValue.split(' ');
      searchValueArr.forEach((val, index) => {
        if (!validateEmail(val)) {
          return;
        }
        if (!this.emailList.includes(val)) {
          this.emailList.push(val);
        }
        if (!this.selectedParticipantEmails.includes(val)) {
          this.selectedParticipantEmails.push(val);
        }
        searchValueArr.splice(index, 1);
      });
      this.emailSearchValue = searchValueArr.join('');
    },
    checkEmailListNull() {
      this.autocompleteHasItem = true;
      const autocomplete = document.querySelector('.autocomplete-custom');
      const autocompleteContent = autocomplete?.querySelector('.v-autocomplete__content');
      const listItem = autocompleteContent?.querySelector('.v-list-item');
      if (!listItem) {
        this.autocompleteHasItem = false;
      }
    },
    onBlurAutoComplete() {
      if (this.emailSearchValue && validateEmail(this.emailSearchValue.trim())) {
        this.onEnterEmail();
      }
    },
    validateFields() {
      this.errors = {
        email: false,
      };

      if (!this.participantsEmail) {
        this.errors.email = true;
        return false;
      }

      return true;
    },
    async send() {
      const isValidate = this.validateFields();
      if (isValidate) {
        const res = await this.sendMeetingRequest(this.payload);
        if (res) {
          this.$emit('close');
        }
      }
    },
  },
  watch: {
    emailSearchValue: {
      handler: debounce(async function () {
        if (this.emailSearchValue && this.emailSearchValue.length > 2) {
          this.emailListSearching = true;
          this.emailList = [...new Set([...this.selectedParticipantEmails])];
          this.emailListSearching = false;
        }
      }, 1000),
    },
  },
};
</script>
<style lang="scss" scoped>
.meeting-request-modal-wrapper {
  .input-wrapper {
    width: 85%;
  }
  .meeting-request-form {
    width: 100%;
  }
  .sub-title {
    font-size: 18px;
  }
  .content {
    font-size: 12px;
    font-weight: 300;
  }
  .information-text {
    font-size: 16px;
    font-weight: 300;
  }
  ::v-deep .autocomplete-custom {
    .v-select__slot {
      .v-label {
        font-size: 13px;
      }
    }
    .v-chip--select {
      background: var(--v-secondary-darken1);
    }
    &.no-item {
      .v-menu__content {
        display: none !important;
      }
    }
  }
  ::v-deep .v-input__slot {
    &::before {
      border-color: transparent !important;
    }
    &::after {
      color: var(--v-info-darken3);
    }
  }
  ::v-deep .v-input {
    .v-input__control {
      .v-input__slot {
        .v-input--radio-group__input {
          .v-radio {
            .v-label {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
  ::v-deep .v-input--is-focused {
    fieldset {
      border: 1px solid #fff !important;
    }
    input {
      caret-color: #fff !important;
    }
  }
}
</style>

