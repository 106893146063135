var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        "max-width": "500",
        "show-actions": "",
        title: _vm.$t("meetingRequest.invite"),
        "cancel-button-text": _vm.$t("cancel"),
        "confirm-button-text": _vm.$t("confirm"),
        "auto-close": false,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-center justify-center meeting-request-modal-wrapper",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-center text-center input-wrapper",
            },
            [
              _c("div", { staticClass: "sub-title my-3" }, [
                _vm._v(_vm._s(_vm.$t("meetingRequest.inviteRequest"))),
              ]),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("meetingRequest.inviteUsersToStream", {
                      sessionName: _vm.sessionName,
                    })
                  )
                ),
              ]),
              _c(
                "v-autocomplete",
                {
                  staticClass: "primary autocomplete-custom mt-3 w-full",
                  class: { "no-item": !_vm.autocompleteHasItem },
                  attrs: {
                    autocomplete: "off",
                    "background-color": "var(--v-primary-lighten1)",
                    color: "var(--v-white-base)",
                    error: _vm.errors.email,
                    "hide-details": "",
                    outlined: "",
                    "deletable-chips": "",
                    multiple: "",
                    "small-chips": "",
                    attach: "",
                    "hide-selected": "",
                    items: _vm.emailList,
                    label: _vm.$t("meetingRequest.emailAddresses"),
                    "search-input": _vm.emailSearchValue,
                    "hide-no-data": !_vm.emailList.length,
                  },
                  on: {
                    "update:searchInput": function ($event) {
                      _vm.emailSearchValue = $event
                    },
                    "update:search-input": function ($event) {
                      _vm.emailSearchValue = $event
                    },
                    blur: _vm.onBlurAutoComplete,
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar",
                        ]) &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      )
                        return null
                      $event.stopPropagation()
                      return _vm.onEnterEmail.apply(null, arguments)
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.checkEmailListNull.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.selectedParticipantEmails,
                    callback: function ($$v) {
                      _vm.selectedParticipantEmails = $$v
                    },
                    expression: "selectedParticipantEmails",
                  },
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _vm.emailListSearching
                      ? _c("div", { staticClass: "autocomplete-data-1" }, [
                          _vm._v(_vm._s(_vm.$t("searching"))),
                        ])
                      : !_vm.emailListSearching
                      ? _c("div", { staticClass: "autocomplete-data-2" })
                      : _vm._e(),
                  ]),
                ],
                2
              ),
              _vm.errors.email
                ? _c(
                    "v-alert",
                    { staticClass: "mt-4", attrs: { type: "error" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "meetingRequest.typeAnEmailAddressAndPressEnter"
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }