var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.$t("removeThisDestination"),
        "max-width": "450",
        "show-close-button": !_vm.loading,
        persistent: _vm.loading,
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c("div", [
        !_vm.selectedBroadcast
          ? _c("div", [
              _vm.providers.length
                ? _c(
                    "div",
                    _vm._l(_vm.providers, function (provider, key) {
                      return _c(
                        "div",
                        { key: provider.userSessionProviderId },
                        [
                          key !== 0
                            ? _c("v-divider", { staticClass: "my-5" })
                            : _vm._e(),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("areYouSureToDeletePost", {
                                  itemName: provider.providerShortCode,
                                })
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("DestinationAvatar", {
                                attrs: {
                                  avatar: provider.avatarUrl,
                                  "destination-types":
                                    provider.providerShortCode,
                                  "icon-position": "right",
                                },
                              }),
                              _c("span", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(
                                    provider.userProviderDetail.displayName
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          provider.userProviderDetail.providerShortCode ===
                          "Youtube"
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      value: false,
                                      label: _vm.$t(
                                        "broadcasts.deleteOnYoutube"
                                      ),
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeDeleteOnYoutube(
                                          provider
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("areYouSureToDelete", {
                          itemName:
                            _vm.broadcastSessionName || _vm.$t("broadcast"),
                        })
                      )
                    ),
                  ]),
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "d-flex align-center mb-4" },
                [
                  _c("DestinationAvatar", {
                    attrs: {
                      avatar: _vm.selectedBroadcast.avatarUrl,
                      "destination-types":
                        _vm.selectedBroadcast.providerShortCode,
                      "icon-position": "right",
                    },
                  }),
                  _c("span", { staticClass: "ml-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.selectedBroadcast.userProviderDetail.displayName
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.$t("areYouSureToDeletePost", {
                      itemName: _vm.selectedBroadcast.providerShortCode,
                    })
                  )
                ),
              ]),
              _vm.selectedBroadcast.providerShortCode === "Youtube"
                ? _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value: false,
                          label: _vm.$t("broadcasts.deleteOnYoutube"),
                          "hide-details": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeDeleteOnYoutube(
                              _vm.selectedBroadcast
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
      ]),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-start mt-4" },
        [
          _c(
            "AppButton",
            {
              staticClass: "mr-2",
              attrs: { color: "var(--v-error-base)", loading: _vm.loading },
              on: { click: _vm.deleteBroadcast },
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("broadcasts.deleteBroadcast")))])]
          ),
          _c(
            "AppButton",
            { attrs: { loading: _vm.loading }, on: { click: _vm.close } },
            [_c("span", [_vm._v(_vm._s(_vm.$t("cancel")))])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }